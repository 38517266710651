import axios from 'axios';
import { format } from 'date-fns';
import enGb from 'date-fns/locale/en-GB/index.js';
import apiEndPoints from './consts/api.js';

const { productionApi, uatApi, apiKey, legacyKey } = apiEndPoints;
export const isProd =
  (process.env.GATSBY_CLOUD ||
    process.env.NETLIFY ||
    process.env.GATSBY_NETLIFY) &&
  (process.env.BRANCH === 'main' || process.env.GATSBY_BRANCH === 'main');

// Note this is a MJS file so we can use it in gatsby-node

const dateTransformer = (data) => {
  if (data instanceof Date) {
    return format(new Date(data), 'yyyy-MM-dd', { locale: enGb });
  }

  if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, dateTransformer(val)])
    );
  }

  if (Array.isArray(data) && data.length >= 1) {
    return data.map((val) => dateTransformer(val));
  }
  return data;
};

export const getApiBaseUrl = () => {
  // Total override environment variable
  if (process.env.GATSBY_API_ENDPOINT) {
    return process.env.GATSBY_API_ENDPOINT;
  }
  return isProd ? productionApi : uatApi;
};

// https://agriapaws-test.agriapet.co.uk/api
export const service = axios.create({
  baseURL: getApiBaseUrl(),
  transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
  headers: {
    'Access-Token': apiKey,
    ApiKey: legacyKey,
    'Content-Type': 'application/json',
  },
});

// service.defaults.headers.common.Origin = origin;
//
